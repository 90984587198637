const namespace = "@/inboxPageActions";

export const SET_EXCLUDE_BLAST_CONVERSATIONS_FILTER = `${namespace}/SET_EXCLUDE_BLAST_CONVERSATIONS_FILTER`;

export const RESET_INBOX_PAGE_REDUCER = `${namespace}/RESET_INBOX_PAGE_REDUCER`;

export const setExcludeBlastConversationsFilter = ({
  shouldExcludeBlastConversations,
}) => ({
  type: SET_EXCLUDE_BLAST_CONVERSATIONS_FILTER,
  shouldExcludeBlastConversations,
});

export const resetInboxPageReducer = () => ({ type: RESET_INBOX_PAGE_REDUCER });
