import { useApolloClient, useSubscription } from "@apollo/client";
import * as Sentry from "@sentry/nextjs";
import { isEmpty, isNil, isNumber } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";

import * as conversationActions from "@/actions/conversationActions";
import * as voiceCallActions from "@/actions/voiceCallActions";
import { useConversationEventSubscriptionContext } from "@/contextProviders/CallProvider/VoiceCallProvider";
import { useLeaderElection } from "@/contextProviders/LeaderElectionProvider";
import { useAppSnackbar } from "@/contextProviders/Snackbar/SnackbarProvider";
import * as conversationListConversationDefinitions from "@/definitions/conversation/conversationListConversationDefinitions";
import { CACHED_CONVERSATION_PROVIDER_CONVERSATION_FRAGMENT } from "@/definitions/conversation/conversationProviderConversationDefinitions";
import { meUserIdDefinition } from "@/definitions/meDefinitions";
import { PAYMENT_SESSION_FRAGMENT } from "@/definitions/paymentSessionDefinitions/inboxPagePaymentSessionDefinition";
import allowedInboxViewEnum, {
  allowedInboxViewOrderByEnum,
} from "@/enums/allowedInboxViewEnum";
import { cachedEventsTargetObjectEnum } from "@/enums/cacheEnum";
import {
  conversationEventTypeEnum,
  conversationStatusEnum,
  conversationStatusInEnum,
} from "@/enums/conversation";
import { messageDirectionEnum } from "@/enums/messageEnum";
import { severityEnum } from "@/enums/styleVariantEnum";
import { dataObjectTypenameEnum } from "@/enums/typename";
import {
  voiceCallEventTypeEnum,
  voiceCallInvitationEventTypeEnum,
  voiceCallParticipantTypeEnum,
  voiceLegTypeEnum,
} from "@/enums/voiceCall";
import inboxPageData from "@/pagesData/inbox";
import * as conversationSelectors from "@/selectors/conversationSelectors";
import * as inboxPageSelectors from "@/selectors/inboxPageSelectors";
import * as voiceCallSelectors from "@/selectors/voiceCallSelectors";
import useMeQuery from "@/services/queryHooks/useMeQuery";
import { useHandlePageQueryChange } from "@/src/utils/hookUtils";
import * as commonUtils from "@/utils/commonUtils";
import * as graphqlUtils from "@/utils/graphqlUtils";
import * as inboxPageRoutingUtils from "@/utils/inboxPageRoutingUtils";
import * as inboxPageUtils from "@/utils/inboxPageUtils";
import * as voiceCallUtils from "@/utils/voiceCallUtils";
import {
  CONVERSATION_EVENTS_LIST_SUBSCRIPTION,
  GET_FALLBACK_EVENT,
} from "./queries";

const ConversationEventSubscription = ({ onPlayNotificationSound }) => {
  const client = useApolloClient();
  const dispatch = useDispatch();
  const router = useRouter();
  const handlePageQueryChange = useHandlePageQueryChange();

  const { inboxViewSection, inboxViewId, orderBy, status, selectedContactId } =
    router.query;

  const ongoingVoiceCalls = useSelector(voiceCallSelectors.ongoingVoiceCalls);

  const endedVoiceConversationsMap = useSelector(
    voiceCallSelectors.endedVoiceConversationsMap,
  );

  const focusedConversationNextPositionData = useSelector(
    conversationSelectors.focusedConversationNextPositionData,
  );
  const parentConversationId = useSelector(
    conversationSelectors.parentConversationId,
  );

  const shouldExcludeBlastConversations = useSelector(
    inboxPageSelectors.shouldExcludeBlastConversations,
  );

  const { onSetAppSnackbarProps } = useAppSnackbar();
  const { isVoiceFeatureLeaderTab } = useLeaderElection();

  const { onToggleNexmoCallAudioInput } =
    useConversationEventSubscriptionContext();

  const { data: meQueryData } = useMeQuery({ objectShape: meUserIdDefinition });

  const setSuccessMessageSnackbar = (message) => {
    onSetAppSnackbarProps({
      message,
      AlertProps: { severity: severityEnum.success },
    });
  };

  const getNewTargetEventsArray = ({
    existingTargetEvents,
    conversationEvent,
    readField,
  }) => {
    const incomingEventRef = {
      __ref: client.cache.identify(conversationEvent),
    };

    const { actionObject, actor } = conversationEvent;
    const incomingEventData = {
      id: conversationEvent.id,
      clientReference: actionObject.clientReference,
    };

    const isSentByMe =
      actor.id === meQueryData.me.id &&
      actor.__typename === meQueryData.me.__typename;

    const createdThreshold = moment(conversationEvent.created).add(1, "minute");
    let replacementIndex = -1;

    /*
      Check if an event with the same id has been fetched within a minute of the current one.
      This takes care of duplicate event edge case when initiating conversation.
    */
    for (let index = 0; index < existingTargetEvents.length; index++) {
      const currentEventRef = existingTargetEvents[index];

      const createdAt = readField("created", currentEventRef);
      if (!isSentByMe && moment(createdAt).isAfter(createdThreshold)) break;

      const isSameEvent = (() => {
        const clientReference = readField(
          "clientReference",
          readField("actionObject", currentEventRef),
        );

        if (clientReference && incomingEventData.clientReference) {
          return clientReference === incomingEventData.clientReference;
        }

        const currentEventId = readField("id", currentEventRef);
        return currentEventId === incomingEventData.id;
      })();

      if (isSameEvent) {
        replacementIndex = index;
        break;
      }
    }

    if (replacementIndex > -1) {
      return commonUtils.replaceInArray({
        array: existingTargetEvents,
        element: incomingEventRef,
        index: replacementIndex,
      });
    }

    const firstEventBeforeIncomingEventIndex = existingTargetEvents.findIndex(
      (ref) =>
        moment(readField("created", ref)).isBefore(
          moment(readField("created", incomingEventRef)),
        ),
    );

    const insertionIndex = (() => {
      if (firstEventBeforeIncomingEventIndex !== -1)
        return firstEventBeforeIncomingEventIndex;

      if (existingTargetEvents.length === 0) return 0;

      return null;
    })();

    /* Skip adding the event to the array if the index is null (not found) */
    if (isNil(insertionIndex)) return;

    return commonUtils.insertInArray({
      array: existingTargetEvents,
      index: insertionIndex,
      element: incomingEventRef,
    });
  };

  const getNewTargetEventsObject = ({
    shouldExcludeEvent,
    conversationEvent,
    existingTargetEventsObject,
    readField,
  }) => {
    if (shouldExcludeEvent) return existingTargetEventsObject;

    const {
      offset = 0,
      results = [],
      totalCount = 0,
    } = existingTargetEventsObject;

    /* Increase offset if not all newer events have been fetched to reflect what backend would have */
    const newOffset = offset > 0 ? offset + 1 : offset;

    const newResults = getNewTargetEventsArray({
      existingTargetEvents: results,
      conversationEvent,
      readField,
    });

    /* Total count increases by 1 every time we add an event to reflect what backend would have */
    const newTotalCount = totalCount + 1;

    return {
      offset: newOffset,
      totalCount: newTotalCount,
      results: newResults,
      __typename: dataObjectTypenameEnum.relatedEventObjectPaginatedListObject,
    };
  };

  const updateOtherEventArrays = ({ conversationEvent }) => {
    const { conversation, eventType } = conversationEvent;

    const { mediaEvents } = inboxPageUtils.separateMessagesFromStandardEvents([
      conversationEvent,
    ]);
    const actionObjectContainsMedia = mediaEvents.length > 0;

    const shouldExcludeFromTimelineEvents =
      conversationListConversationDefinitions.timelineEventsExclusionArray.includes(
        eventType,
      );

    const conversationEventsQueryKey = graphqlUtils.getRootQueryKey({
      queryName: "events",
    });

    client.cache.modify({
      id: client.cache.identify(conversation),
      fields: {
        [conversationEventsQueryKey]: (existing = {}, { readField }) => {
          const existingEventsWithMediaObject =
            existing[cachedEventsTargetObjectEnum.eventsWithMedia];

          const existingTimelineEventsObject =
            existing[cachedEventsTargetObjectEnum.timelineEvents];

          const newEventsWithMediaObject = isEmpty(
            existingEventsWithMediaObject,
          )
            ? undefined
            : getNewTargetEventsObject({
                shouldExcludeEvent: !actionObjectContainsMedia,
                conversationEvent,
                existingTargetEventsObject: existingEventsWithMediaObject,
                readField,
              });

          const newTimelineEventsObject = isEmpty(existingTimelineEventsObject)
            ? undefined
            : getNewTargetEventsObject({
                shouldExcludeEvent: shouldExcludeFromTimelineEvents,
                conversationEvent,
                existingTargetEventsObject: existingTimelineEventsObject,
                readField,
              });

          return {
            ...existing,
            [cachedEventsTargetObjectEnum.eventsWithMedia]:
              newEventsWithMediaObject,

            [cachedEventsTargetObjectEnum.timelineEvents]:
              newTimelineEventsObject,
          };
        },
      },
    });
  };

  const addConversationEvent = ({ conversationEvent }) => {
    const { conversation } = conversationEvent;

    const conversationEventsQueryKey = graphqlUtils.getRootQueryKey({
      queryName: "events",
    });

    client.cache.modify({
      id: client.cache.identify(conversation),
      fields: {
        [conversationEventsQueryKey]: (existing = {}, { readField }) => {
          const existingAllEventsObject =
            existing[cachedEventsTargetObjectEnum.allEvents];

          const newExistingAllEventsObject = isEmpty(existingAllEventsObject)
            ? undefined
            : getNewTargetEventsObject({
                /*
                  Prevent rendering last sent/received conversation event in the
                  chat panel when not all recent events have been fetched.
                */
                shouldExcludeEvent: existingAllEventsObject.offset > 0,
                conversationEvent,
                existingTargetEventsObject: existingAllEventsObject,
                readField,
              });

          return {
            ...existing,
            [cachedEventsTargetObjectEnum.mostRecentEvents]: {
              offset: 0,
              totalCount: 1,
              results: [{ __ref: client.cache.identify(conversationEvent) }],
              __typename:
                dataObjectTypenameEnum.relatedEventObjectPaginatedListObject,
            },
            [cachedEventsTargetObjectEnum.allEvents]:
              newExistingAllEventsObject,
          };
        },
      },
    });

    updateOtherEventArrays({ conversationEvent });
  };

  const addConversationToQuery = ({ conversationRef, direction }) => {
    const statusIn = (() => {
      switch (status) {
        case conversationStatusEnum.active: {
          return conversationStatusInEnum.active;
        }

        case conversationStatusEnum.resolved: {
          return conversationStatusInEnum.resolved;
        }
      }
    })();

    const key = inboxPageUtils.getAllowedInboxViewKeyInRoot({
      statusIn,
      ...inboxPageRoutingUtils.getInboxViewInput({
        inboxViewSection,
        inboxViewId,
      }),
    });

    client.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        [key]: (existing = {}, { readField }) => {
          const indexAfterPrioritySection =
            orderBy === allowedInboxViewOrderByEnum.priorityFirst &&
            inboxPageUtils.getPriorityFirstOrderingConversationInsertIndex({
              existing,
              conversationRef,
              readField,
            });

          const insertAt = indexAfterPrioritySection || direction;

          const { newResults, newTotalCount } =
            graphqlUtils.addToQueryResultsWithTotalCount({
              newItem: conversationRef,
              existing,
              equalityFn: (ref) =>
                readField("id", ref) === readField("id", conversationRef),
              insertAt,
            });

          return {
            results: newResults,
            totalCount: newTotalCount,
            __typename:
              dataObjectTypenameEnum.conversationObjectPaginatedListObject,
          };
        },
      },
    });
  };

  const checkIfVoiceCallIsInReducer = ({
    conversationId,
    voiceConversationId,
  }) => {
    const isVoiceCallInReducer = ongoingVoiceCalls.some((ongoingVoiceCall) => {
      return (
        ongoingVoiceCall.conversationId === conversationId &&
        ongoingVoiceCall.voiceConversation.id === voiceConversationId
      );
    });

    return isVoiceCallInReducer;
  };

  const removeConversationFromQuery = ({ conversation, contactId }) => {
    const { id, lastVoiceConversation } = conversation;

    const isVoiceCallInReducer = checkIfVoiceCallIsInReducer({
      conversationId: id,
      voiceConversationId: lastVoiceConversation?.id,
    });

    inboxPageUtils.removeConversationFromQueryCache({
      client,
      conversation,
      inboxViewSection,
      inboxViewId,
      contactId,
    });

    /* Remove voice call from reducer if it is still there */
    if (isVoiceCallInReducer) {
      /* Check if agent is still a participant in the voice call */
      const currentAgent = voiceCallUtils.getParticipantFromVoiceCall({
        voiceCallParticipants: lastVoiceConversation?.currentParticipants,
        participantType: voiceCallParticipantTypeEnum.agent,
        participantId: meQueryData.me.id,
      });

      if (!currentAgent) {
        dispatch(
          voiceCallActions.removeOngoingVoiceCall({
            conversationId: id,
          }),
        );
      }
    }
  };

  const handleConversationTransfer = ({ conversationEvent }) => {
    const { actor, target, conversation } = conversationEvent;

    const { id, assignee, lastVoiceConversation } = conversation;
    const agent = commonUtils.getAgentFullName(target);
    const isTransferredByMe =
      actor.id === meQueryData.me.id &&
      actor.__typename === meQueryData.me.__typename;

    const isConversationAssignedToMe =
      assignee?.id === meQueryData.me.id &&
      assignee?.__typename === meQueryData.me.__typename;

    const isFocusedOnConversation =
      router.pathname === inboxPageData.urlObject.pathname &&
      router.query.conversationId === conversation.id;

    const isAutomaticTransfer =
      !isTransferredByMe &&
      !isConversationAssignedToMe &&
      isFocusedOnConversation;

    const isGroup = target.__typename === dataObjectTypenameEnum.groupObject;

    const isCallInReducer = checkIfVoiceCallIsInReducer({
      conversationId: id,
      voiceConversationId: lastVoiceConversation?.id,
    });

    if (isConversationAssignedToMe) {
      setSuccessMessageSnackbar("Conversation transferred to you");
    }

    if (isAutomaticTransfer) {
      setSuccessMessageSnackbar(
        `Conversation transferred to ${isGroup ? target.name : agent}`,
      );
    }

    const isEndVoiceCallInitiated =
      !!endedVoiceConversationsMap[lastVoiceConversation?.id];

    if (isEndVoiceCallInitiated) {
      dispatch(
        voiceCallActions.removeEndedVoiceCall({
          voiceConversationId: lastVoiceConversation.id,
        }),
      );
    }

    if (isCallInReducer) {
      dispatch(
        voiceCallActions.updateOngoingVoiceCall({
          conversationId: id,
          voiceConversation: lastVoiceConversation,
        }),
      );
    }
  };

  const addToPastConversations = ({ conversation, conversationRef }) => {
    const { contact } = conversation;
    if (!contact) return;

    const key = inboxPageUtils.getPastConversationsKeyInRoot({
      statusIn: conversationStatusInEnum.resolved,
      contactId: contact.id,
    });

    client.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        [key]: (existing = {}, { readField }) => {
          const { newResults, newTotalCount } =
            graphqlUtils.addToQueryResultsWithTotalCount({
              newItem: conversationRef,
              existing,
              equalityFn: (ref) =>
                readField("id", ref) === readField("id", conversationRef),
              insertAt: 0,
            });

          return {
            results: newResults,
            totalCount: newTotalCount,
            __typename: dataObjectTypenameEnum.conversationObjectListObject,
          };
        },
      },
    });
  };

  const handleConversationResolved = ({
    conversationEvent,
    conversationRef,
  }) => {
    const { actor, conversation } = conversationEvent;
    const { contact } = conversation;
    const conversationResolvedByMe =
      actor.id === meQueryData.me.id &&
      actor.__typename === meQueryData.me.__typename;

    const isFocusedOnConversation =
      router.pathname === inboxPageData.urlObject.pathname &&
      router.query.conversationId === conversation.id;

    /* Add conversation to past conversations for the contact */
    addToPastConversations({ conversation, conversationRef });

    /* Alert agent in snackbar if some other agent resolved the conversation they were viewing */
    if (isFocusedOnConversation && !conversationResolvedByMe) {
      const resolver =
        inboxPageUtils.getConversationEventUnionObjectLabel(actor);
      setSuccessMessageSnackbar(`Conversation resolved by ${resolver}`);
    }

    /* Invalidate conversation list forcing a re-fetch if agent is viewing conversations through contact search */
    if (selectedContactId === contact.id) {
      const contactKey = inboxPageUtils.getContactConversationsKeyInRoot({
        contactId: selectedContactId,
      });

      client.cache.modify({
        id: "ROOT_QUERY",
        fields: { [contactKey]: () => undefined },
      });
    }
  };

  const handleVoiceCallStarted = ({
    conversation,
    eventType,
    voiceConversation,
  }) => {
    const { currentParticipants } = voiceConversation || {};
    const myParticipant = currentParticipants?.find(
      (participant) => participant.agent?.id === meQueryData.me.id,
    );

    const isCallStartedByMe = voiceCallUtils.getIsVoiceCallStartedByMe({
      userId: meQueryData.me.id,
      voiceConversation,
    });

    if (!myParticipant) {
      if (isCallStartedByMe) {
        /*
          Reset calling state if agent starts the call but is not a participant
          This may happen if the call fails very quick
          and causes VOICE_CALL_STARTED event to exclude the caller agent from participant list
        */
        dispatch(
          voiceCallActions.setConversationStartingVoiceCall({
            conversationId: null,
          }),
        );
      }
      return;
    }

    const ignoreVoiceCallStartedEvent =
      eventType === voiceCallEventTypeEnum.VOICE_CALL_STARTED &&
      !isCallStartedByMe;

    /* Ignore the VOICE_CALL_STARTED event for incoming calls and wait for VOICE_AGENT_IP_CALL */
    if (ignoreVoiceCallStartedEvent) return;

    dispatch(
      voiceCallActions.addInitiatedVoiceCall({
        conversationId: conversation.id,
        voiceConversation,
      }),
    );
  };

  const handleMuteVoiceCallMonitorAgent = ({ voiceConversation }) => {
    const { lastVoiceLegOfMe } = voiceConversation;
    const isCallMonitorStartedByMe =
      lastVoiceLegOfMe?.legType === voiceLegTypeEnum.CALL_MONITOR;

    if (!lastVoiceLegOfMe || !isCallMonitorStartedByMe) {
      return;
    }

    /* Restore the call monitor audio input to its original state by unmuting it */
    onToggleNexmoCallAudioInput({ isAudioEnabled: true, voiceConversation });
  };

  const handleVoiceCallCancelInvitation = ({
    conversation,
    eventType,
    voiceConversation,
  }) => {
    const shouldHideCancelVoiceInviteBanner =
      !!voiceCallInvitationEventTypeEnum[eventType];

    if (shouldHideCancelVoiceInviteBanner) {
      const hasInactiveParticipant =
        voiceConversation?.currentParticipants.some(
          ({ isActive }) => !isActive,
        );

      if (!hasInactiveParticipant) {
        dispatch(
          voiceCallActions.setCancelVoiceCallInviteState({
            conversationId: conversation.id,
            isCancelVoiceCallInviteShown: false,
            invitedAt: null,
          }),
        );
      }
    }
  };

  const handleVoiceCallEvent = ({ conversationEvent }) => {
    const voiceConversation = (() => {
      const { actionObject, target } = conversationEvent;

      const isTargetAVoiceConversationObject =
        target?.__typename === dataObjectTypenameEnum.voiceConversationObject;

      const isActionObjectAVoiceConversationObject =
        actionObject.__typename ===
        dataObjectTypenameEnum.voiceConversationObject;

      if (isTargetAVoiceConversationObject) return target;
      if (isActionObjectAVoiceConversationObject) return actionObject;
    })();

    if (!voiceConversation) return;

    const { eventType, conversation } = conversationEvent;

    const isCallInReducer = checkIfVoiceCallIsInReducer({
      conversationId: conversation.id,
      voiceConversationId: voiceConversation.id,
    });

    const isStartVoiceCallEvent =
      eventType === voiceCallEventTypeEnum.VOICE_CALL_STARTED ||
      eventType === voiceCallEventTypeEnum.VOICE_CALL_JOINED ||
      eventType === voiceCallEventTypeEnum.VOICE_CALL_MONITOR_STARTED ||
      eventType === voiceCallEventTypeEnum.VOICE_AGENT_IP_CALL;

    const isEndVoiceCallInitiated =
      !!endedVoiceConversationsMap[voiceConversation.id];

    const shouldAddCallToReducer =
      isVoiceFeatureLeaderTab &&
      isStartVoiceCallEvent &&
      !isCallInReducer &&
      !isEndVoiceCallInitiated;

    const currentAgent = voiceCallUtils.getParticipantFromVoiceCall({
      voiceCallParticipants: voiceConversation.currentParticipants,
      participantType: voiceCallParticipantTypeEnum.agent,
      participantId: meQueryData.me.id,
    });

    /*
        Remove the ended voice call from reducer if the current agent is no longer in the call.
        User may receive a transferred conversation that is still using the same VoiceConversation object.
      */
    if (isEndVoiceCallInitiated && !currentAgent) {
      dispatch(
        voiceCallActions.removeEndedVoiceCall({
          voiceConversationId: voiceConversation.id,
        }),
      );
    }

    if (shouldAddCallToReducer) {
      handleVoiceCallStarted({ conversation, eventType, voiceConversation });
      return;
    }

    /* Return early if we don't have the call in the reducer */
    if (!isCallInReducer) return;

    dispatch(
      voiceCallActions.updateOngoingVoiceCall({
        conversationId: conversation.id,
        voiceConversation,
      }),
    );

    /*
      Delay removing ongoing voice call so the useEffect in VoiceCallProvider.js that
      send SYNC_ACTIVE_VOICE_CALL_DATA can subscribe to updateOngoingVoiceCall changes before it gets removed
    */
    if (!currentAgent) {
      setTimeout(
        () =>
          dispatch(
            voiceCallActions.removeOngoingVoiceCall({
              conversationId: conversation.id,
            }),
          ),
        500,
      );
      return;
    }

    handleVoiceCallCancelInvitation({
      conversation,
      eventType,
      voiceConversation,
    });

    const isVoiceCallLegMutedEvent =
      eventType === voiceCallEventTypeEnum.VOICE_CALL_LEG_MUTED;

    if (isVoiceCallLegMutedEvent) {
      handleMuteVoiceCallMonitorAgent({ voiceConversation });
    }
  };

  const handleConversationAssignment = ({ conversationEvent }) => {
    const { assignee } = conversationEvent.conversation;
    const isConversationAssignedToMe =
      assignee?.id === meQueryData.me.id &&
      assignee?.__typename === meQueryData.me.__typename;

    if (isConversationAssignedToMe) {
      setSuccessMessageSnackbar("Conversation assigned to you");
    }
  };

  const handlePaymentSessionInitiated = ({ conversationEvent }) => {
    const { actionObject, conversation } = conversationEvent;
    const { contact } = conversation;

    const paymentSessionRef = client.cache.writeFragment({
      fragment: PAYMENT_SESSION_FRAGMENT,
      data: { ...actionObject, conversation },
    });

    const paymentSessionsKey = graphqlUtils.getRootQueryKey({
      queryName: "paymentSessions",
      keyArgArray: [{ keyArg: "contactId", value: contact.id }],
    });

    /* Update the payment sessions for the contact when a new one is initiated */
    client.cache.modify({
      id: "ROOT_QUERY",
      fields: {
        [paymentSessionsKey]: (existing = {}) => {
          return {
            ...existing,
            results: [paymentSessionRef, ...existing.results],
            totalCount: existing.totalCount + 1,
          };
        },
      },
    });
  };

  const handleVoiceCallRecordingPlaybackReady = ({ conversationEvent }) => {
    const recordingObjectRef = client.cache.identify(conversationEvent.target);

    client.cache.modify({
      id: recordingObjectRef,
      fields: { media: () => conversationEvent.target.media },
    });
  };

  const handleVoiceCallTranscriptionCompleted = ({ conversationEvent }) => {
    const recordingObjectRef = client.cache.identify(
      conversationEvent.actionObject,
    );

    client.cache.modify({
      id: recordingObjectRef,
      fields: { isTranscriptionCompleted: () => true },
    });
  };

  const handleSpecificEventType = ({ conversationEvent, conversationRef }) => {
    switch (conversationEvent.eventType) {
      case conversationEventTypeEnum.conversationAssignment: {
        handleConversationAssignment({ conversationEvent });
        break;
      }

      case conversationEventTypeEnum.conversationTransfer: {
        handleConversationTransfer({ conversationEvent });
        break;
      }

      case conversationEventTypeEnum.conversationResolved: {
        handleConversationResolved({ conversationEvent, conversationRef });
        break;
      }

      case conversationEventTypeEnum.paymentSessionInitiated: {
        handlePaymentSessionInitiated({ conversationEvent });
        break;
      }

      case voiceCallEventTypeEnum.VOICE_CALL_RECORDING_PLAYBACK_READY: {
        handleVoiceCallRecordingPlaybackReady({ conversationEvent });
        break;
      }

      case voiceCallEventTypeEnum.VOICE_CALL_TRANSCRIPTION_COMPLETED: {
        handleVoiceCallTranscriptionCompleted({ conversationEvent });
        break;
      }

      default: {
        break;
      }
    }

    const isVoiceCallEvent =
      voiceCallEventTypeEnum[conversationEvent.eventType];

    if (isVoiceCallEvent) {
      handleVoiceCallEvent({ conversationEvent });
    }
  };

  const getNextFocusedConversationPosition = ({
    direction,
    isFocusedOnConversation,
    isEventConversationPrioritized,
  }) => {
    if (isFocusedOnConversation || isEmpty(focusedConversationNextPositionData))
      return direction;

    switch (orderBy) {
      /* Move to bottom before newer events if any. */
      case allowedInboxViewOrderByEnum.oldest: {
        return -1 + focusedConversationNextPositionData.nextPosition;
      }

      /* Move to top of respective priority level after newer events if any. */
      case allowedInboxViewOrderByEnum.priorityFirst: {
        const shouldAddToPriorityPosition =
          focusedConversationNextPositionData.isPriority &&
          isEventConversationPrioritized;

        const shouldAddToNonPriorityPosition =
          !focusedConversationNextPositionData.isPriority &&
          !isEventConversationPrioritized;

        /* Increase index only if the incoming event conversation has the same priority level. */
        return shouldAddToPriorityPosition || shouldAddToNonPriorityPosition
          ? focusedConversationNextPositionData.nextPosition + 1
          : focusedConversationNextPositionData.nextPosition;
      }

      /* Move to top after newer events if any. */
      case allowedInboxViewOrderByEnum.newest: {
        return focusedConversationNextPositionData.nextPosition + 1;
      }

      default:
        direction;
    }
  };

  const handleEventsReset = ({ conversationEvent }) => {
    const { conversation } = conversationEvent;

    const conversationEventsQueryKey = graphqlUtils.getRootQueryKey({
      queryName: "events",
    });

    client.cache.modify({
      id: client.cache.identify(conversation),
      fields: {
        [conversationEventsQueryKey]: (existing) => {
          const existingMostRecentEvents =
            existing[cachedEventsTargetObjectEnum.mostRecentEvents];

          if (!existingMostRecentEvents) return existing;

          return {
            [cachedEventsTargetObjectEnum.mostRecentEvents]: {
              ...existingMostRecentEvents,
              results: [{ __ref: client.cache.identify(conversationEvent) }],
            },
          };
        },
      },
    });
  };

  const handleConversationPermissionChanges = ({
    conversationEvent,
    isConversationInCache,
  }) => {
    const { conversation } = conversationEvent;

    const shouldRemoveConversation = (() => {
      if (selectedContactId) {
        const isAgentAllowedToViewConversation =
          conversation.allowedInboxView.special.length > 0;

        return !isAgentAllowedToViewConversation;
      }

      const isInboxViewStillValid =
        inboxPageRoutingUtils.validateInboxPageUrlForConversation({
          router,
          conversationStatus: conversation.conversationStatus,
          conversationAllowedInboxView: conversation.allowedInboxView,
        });

      return !isInboxViewStillValid;
    })();

    if (shouldRemoveConversation) {
      removeConversationFromQuery({
        conversation: conversation,
        contactId: selectedContactId,
      });

      const isFocusedOnConversation =
        router.pathname === inboxPageData.urlObject.pathname &&
        router.query.conversationId === conversation.id;

      /** Remove focus from conversation if:
       * - We are focused on it
       * - If it is not a past conversation
       */
      if (isFocusedOnConversation && !parentConversationId) {
        handlePageQueryChange({
          queryProps: { conversationId: null, eventId: null },
        });

        dispatch(conversationActions.resetConversation());
      }
    }

    const isConversationTransfer =
      conversationEvent.eventType ===
      conversationEventTypeEnum.conversationTransfer;

    const conversationAssignment = client.readFragment({
      id: client.cache.identify(conversation),
      fragment:
        conversationListConversationDefinitions.CONVERSATION_ASSIGNMENT_FRAGMENT,
      variables: { getPreviousAssignee: true },
    });

    /** Reset events on transfer if the conversation was previously assigned to an agent */
    const shouldResetEventsOnConversationTransfer =
      isConversationTransfer && !!conversationAssignment.assignee;

    const shouldResetEvents =
      isConversationInCache && shouldResetEventsOnConversationTransfer;

    if (shouldResetEvents) handleEventsReset({ conversationEvent });
  };

  /*
    This function handles inserting and reordering conversations as needed when
    the agent is not in search contact mode
  */
  const modifyConversationsInAllowedInboxView = ({
    shouldExcludeConversationEvent,
    isConversationInCache,
    conversation,
    conversationRef,
  }) => {
    const {
      id: eventConversationId,
      isPriority: isEventConversationPrioritized,
    } = conversation;

    const shouldReorder =
      orderBy !== allowedInboxViewOrderByEnum.waitingLongest &&
      orderBy !== allowedInboxViewOrderByEnum.idleLongest;

    /* -1 means it should be placed at the bottom of the list, 0 means it should be somewhere on top (default). */
    const direction =
      orderBy === allowedInboxViewOrderByEnum.oldest ||
      orderBy === allowedInboxViewOrderByEnum.waitingLongest ||
      orderBy === allowedInboxViewOrderByEnum.idleLongest
        ? -1
        : 0;

    const isFocusedOnConversation =
      router.pathname === inboxPageData.urlObject.pathname &&
      router.query.conversationId === conversation.id;

    const isInboxViewStillValid =
      inboxPageRoutingUtils.validateInboxPageUrlForConversation({
        router,
        conversationStatus: conversation.conversationStatus,
        conversationAllowedInboxView: conversation.allowedInboxView,
      });

    const shouldAddToConversationsQuery =
      !isFocusedOnConversation &&
      !shouldExcludeConversationEvent &&
      isInboxViewStillValid;

    const shouldRemoveFromRootConversationsQuery =
      !isInboxViewStillValid ||
      (!isFocusedOnConversation &&
        !shouldExcludeConversationEvent &&
        isConversationInCache &&
        shouldReorder);

    if (shouldRemoveFromRootConversationsQuery)
      removeConversationFromQuery({ conversation });

    if (shouldAddToConversationsQuery)
      addConversationToQuery({ conversationRef, direction });

    if (isInboxViewStillValid && shouldReorder) {
      const nextFocusedConversationPosition =
        getNextFocusedConversationPosition({
          direction,
          isFocusedOnConversation,
          isEventConversationPrioritized,
        });

      isNumber(nextFocusedConversationPosition) &&
        dispatch(
          conversationActions.setFocusedConversationNextPositionData({
            focusedConversationNextPositionData: {
              id: isFocusedOnConversation
                ? eventConversationId
                : focusedConversationNextPositionData.id,
              isPriority: isFocusedOnConversation
                ? isEventConversationPrioritized
                : focusedConversationNextPositionData.isPriority,
              nextPosition: nextFocusedConversationPosition,
            },
          }),
        );
    }
  };

  const handleNotificationSound = ({ conversationEvent }) => {
    const { conversation } = conversationEvent;

    const isConversationAssignedToMe =
      conversation.assignee?.id === meQueryData.me.id &&
      conversation.assignee?.__typename === meQueryData.me.__typename;

    /* Do nothing if the conversation is not assigned to the agent */
    if (!isConversationAssignedToMe) return;

    const { eventType, actionObject } = conversationEvent;

    const isConversationAssignment =
      eventType === conversationEventTypeEnum.conversationAssignment;

    const isConversationTransfer =
      eventType === conversationEventTypeEnum.conversationTransfer;

    const isIncomingMessage =
      eventType === conversationEventTypeEnum.message &&
      actionObject.direction === messageDirectionEnum.incoming;

    const isIncomingNote =
      eventType === conversationEventTypeEnum.conversationNote &&
      actionObject.noteWriter.id !== meQueryData.me.id &&
      actionObject.noteWriter.__typename !== meQueryData.me.__typename;

    const shouldPlaySound =
      isConversationAssignment ||
      isConversationTransfer ||
      isIncomingMessage ||
      isIncomingNote;

    if (shouldPlaySound) onPlayNotificationSound();
  };

  const handleIncomingConversationEvent = ({ conversationEvent }) => {
    const { eventType, conversation } = conversationEvent;

    const shouldIgnoreEvent = (() => {
      const isNewInboxView = inboxViewId === allowedInboxViewEnum.new;

      const isBlastConversation =
        conversationListConversationDefinitions.blastConversationCreatedSources.includes(
          conversation.createdSource,
        );

      const shouldIgnoreMessageBlastConversationSource =
        isNewInboxView &&
        shouldExcludeBlastConversations &&
        isBlastConversation;

      return shouldIgnoreMessageBlastConversationSource;
    })();

    if (shouldIgnoreEvent) return;

    const shouldExcludeConversationEvent =
      conversationListConversationDefinitions.conversationEventsExclusionArray.includes(
        eventType,
      );

    /*
      We can have 2 ways a conversation can exist in the cache:
        1. If it was fetched from the conversation list.
        2. If it was fetched from the conversation provider.
    */
    const cachedConversationListConversation = client.readFragment({
      id: client.cache.identify(conversation),
      fragment:
        conversationListConversationDefinitions.CACHED_CONVERSATION_LIST_ITEM_FRAGMENT,
      variables: {
        targetEventsObject: cachedEventsTargetObjectEnum.mostRecentEvents,
      },
    });

    const cachedConversationProviderCOnversation = client.readFragment({
      id: client.cache.identify(conversation),
      fragment: CACHED_CONVERSATION_PROVIDER_CONVERSATION_FRAGMENT,
    });

    const isConversationInCache =
      !!cachedConversationListConversation ||
      !!cachedConversationProviderCOnversation;

    const conversationRef = (() => {
      /*
        Write conversation to cache. Exclude ignored event from events array to let logic that could cause
        UI updates to run. e.g. Some voice events ignored in the events array cause UI and reducer updates.
      */

      const newMostRecentEvents = (() => {
        if (cachedConversationListConversation) {
          const { events: cachedMostRecentEvents } =
            cachedConversationListConversation;

          if (shouldExcludeConversationEvent) return cachedMostRecentEvents;

          return {
            offset: 0,
            totalCount: 1,
            results: [conversationEvent],
            __typename:
              dataObjectTypenameEnum.relatedEventObjectPaginatedListObject,
          };
        } else {
          return {
            offset: 0,
            totalCount: shouldExcludeConversationEvent ? 0 : 1,
            results: shouldExcludeConversationEvent ? [] : [conversationEvent],
            __typename:
              dataObjectTypenameEnum.relatedEventObjectPaginatedListObject,
          };
        }
      })();

      const ref = client.cache.writeFragment({
        fragment:
          conversationListConversationDefinitions.NEW_CONVERSATION_LIST_ITEM_FRAGMENT,
        data: { ...conversation, events: newMostRecentEvents },
        variables: {
          targetEventsObject: cachedEventsTargetObjectEnum.mostRecentEvents,
        },
      });

      if (isConversationInCache && !shouldExcludeConversationEvent) {
        addConversationEvent({ conversationEvent });
      }

      return ref;
    })();

    if (!conversationRef) return;

    /* Run code to handle ordering changes when the agent is not in contact search mode */
    if (!selectedContactId) {
      modifyConversationsInAllowedInboxView({
        shouldExcludeConversationEvent,
        isConversationInCache,
        conversation,
        conversationRef,
      });
    }

    handleSpecificEventType({ conversationEvent, conversationRef });

    handleConversationPermissionChanges({
      conversationEvent,
      isConversationInCache,
    });

    handleNotificationSound({ conversationEvent });
  };

  /* If backend returns null conversation object, refetch event from the server and process it normally */
  const refetchMissingConversationObjectEventData = async (eventId) => {
    try {
      const { data: { fallbackEvent = {} } = {} } = await client.query({
        query: GET_FALLBACK_EVENT,
        fetchPolicy: "no-cache",
        variables: { id: eventId },
      });

      const { target, actionObject, conversation } = fallbackEvent;

      const isTargetAConversationObject =
        target?.__typename === dataObjectTypenameEnum.conversationObject;

      const isActionObjectAConversationObject =
        actionObject.__typename === dataObjectTypenameEnum.conversationObject;

      /* Replace __typename so we do not write FallbackEventObject into ConversationObject */
      const conversationEvent = {
        ...fallbackEvent,
        ...(isTargetAConversationObject && { target: conversation }),
        ...(isActionObjectAConversationObject && {
          actionObject: conversation,
        }),
        __typename: dataObjectTypenameEnum.relatedEventObject,
      };

      handleIncomingConversationEvent({ conversationEvent });
    } catch (error) {
      console.error(error);
    }
  };

  /* Subscription to listen for incoming conversation list of events */
  useSubscription(CONVERSATION_EVENTS_LIST_SUBSCRIPTION, {
    errorPolicy: "all",
    onData: ({ data }) => {
      const events = data.data?.onNewConversationEvents.events;
      if (!events) return;

      events.forEach((event) => {
        const { target, actionObject, conversation } = event;

        const isTargetAConversationObject =
          target?.__typename === dataObjectTypenameEnum.conversationObject;

        const isActionObjectAConversationObject =
          actionObject.__typename === dataObjectTypenameEnum.conversationObject;

        const filledEvent = {
          ...event,
          ...(isTargetAConversationObject && { target: conversation }),
          ...(isActionObjectAConversationObject && {
            actionObject: conversation,
          }),
        };

        if (conversation) {
          handleIncomingConversationEvent({ conversationEvent: filledEvent });
        } else {
          refetchMissingConversationObjectEventData(event.id);

          /* Log the error in webclient Sentry so we get noticed when this issue occurs again */
          Sentry.captureException(
            new Error(
              "Error in ConversationEventSubscription: Missing conversation object (null) returned by backend",
            ),
          );
        }
      });
    },
  });

  return null;
};

export default ConversationEventSubscription;
