import { ErrorBoundary } from "@sentry/nextjs";

import FloatingCallWidgetContainer from "@/components/Call/FloatingCallWidgetContainer";
import RichTextEditorSubscriptions from "@/components/RichTextEditor/components/RichTextEditorSubscriptions";
import ServiceStatusIndicator from "@/components/ServiceStatusIndicator";
import { useAuthContext } from "@/contextProviders/AuthProvider";
import { useServiceStatus } from "@/contextProviders/ServiceStatusProvider";
import ErrorPage from "@/src/components/ErrorPage";
import InboxPageSubscriptions from "./InboxPageSubscriptions";
import PushNotificationManager from "./PushNotificationManager";

const AppLayout = ({ children }) => {
  const { isUserLoggedIn } = useAuthContext();
  const { isServiceStatusIndicatorShown } = useServiceStatus();

  return (
    <>
      {isServiceStatusIndicatorShown && (
        <ErrorBoundary fallback={null}>
          <ServiceStatusIndicator />
        </ErrorBoundary>
      )}

      <ErrorBoundary fallback={<ErrorPage />}>{children}</ErrorBoundary>

      {isUserLoggedIn && (
        <>
          <ErrorBoundary fallback={null}>
            <InboxPageSubscriptions />
          </ErrorBoundary>

          <ErrorBoundary fallback={null}>
            <PushNotificationManager />
          </ErrorBoundary>

          <ErrorBoundary fallback={null}>
            <RichTextEditorSubscriptions />
          </ErrorBoundary>

          {/* Need to render this here so the widget container can move across whole page */}
          <FloatingCallWidgetContainer />
        </>
      )}
    </>
  );
};

export default AppLayout;
