const namespace = "@/conversationActions";

export const SEND_CONVERSATION_MESSAGE = `${namespace}/SEND_CONVERSATION_MESSAGE`;
export const SEND_CONVERSATION_NOTE = `${namespace}/SEND_CONVERSATION_NOTE`;

export const TOGGLE_MEDIA_PREVIEW = `${namespace}/TOGGLE_MEDIA_PREVIEW`;
export const SET_HAS_MORE_MEDIA = `${namespace}/SET_HAS_MORE_MEDIA`;
export const SET_CURRENT_MEDIA_CURSOR_OFFSET =
  "SET_CURRENT_MEDIA_CURSOR_OFFSET";

export const SET_FIRST_ITEM_INDEX = `${namespace}/SET_FIRST_ITEM_INDEX`;
export const SET_FIRST_CONVERSATION_EVENT_ID = `${namespace}/SET_FIRST_CONVERSATION_EVENT_ID`;

export const SET_PARENT_CONVERSATION_ID = `${namespace}/SET_PARENT_CONVERSATION_ID`;

export const SET_HAS_PREVIOUS_CONVERSATION = `${namespace}/SET_HAS_PREVIOUS_CONVERSATION`;

export const RESET_CONVERSATION = `${namespace}/RESET_CONVERSATION`;

export const START_INITIATION = `${namespace}/START_INITIATION`;

export const SET_CONVERSATION_INITIATION_STATE = `${namespace}/SET_CONVERSATION_INITIATION_STATE`;

export const SET_IS_VOICE_CALL_WITH_OTHER_AGENT = `${namespace}/SET_IS_VOICE_CALL_WITH_OTHER_AGENT`;

export const SET_FOCUSED_CONVERSATION_NEXT_POSITION_DATA = `${namespace}/SET_FOCUSED_CONVERSATION_NEXT_POSITION_DATA`;

export const sendConversationMessage = (actionData) => ({
  ...actionData,
  type: SEND_CONVERSATION_MESSAGE,
});

export const sendConversationNote = (actionData) => ({
  ...actionData,
  type: SEND_CONVERSATION_NOTE,
});

export const toggleMediaPreview = ({
  isMediaPreviewShown,
  currentMediaPreviewId,
}) => ({
  type: TOGGLE_MEDIA_PREVIEW,
  isMediaPreviewShown,
  currentMediaPreviewId,
});

export const setCurrentMediaCursorOffset = ({ currentMediaCursorOffset }) => ({
  type: SET_CURRENT_MEDIA_CURSOR_OFFSET,
  currentMediaCursorOffset,
});

export const setHasMoreMedia = ({ hasMoreMedia }) => ({
  type: SET_HAS_MORE_MEDIA,
  hasMoreMedia,
});

export const setFirstItemIndex = ({ firstItemIndex }) => ({
  type: SET_FIRST_ITEM_INDEX,
  firstItemIndex,
});

export const setFirstConversationEventId = ({ firstConversationEventId }) => ({
  type: SET_FIRST_CONVERSATION_EVENT_ID,
  firstConversationEventId,
});

export const setParentConversationId = ({ parentConversationId }) => ({
  type: SET_PARENT_CONVERSATION_ID,
  parentConversationId,
});

export const setHasPreviousConversation = ({ hasPreviousConversation }) => {
  return { type: SET_HAS_PREVIOUS_CONVERSATION, hasPreviousConversation };
};

export const resetConversation = ({
  firstItemIndex = null,
  parentConversationId = null,
} = {}) => ({
  type: RESET_CONVERSATION,
  firstItemIndex,
  parentConversationId,
});

export const startInitiation = () => {
  return { type: START_INITIATION };
};

export const setConversationInitiationState = (conversationInitiationState) => {
  return {
    type: SET_CONVERSATION_INITIATION_STATE,
    conversationInitiationState,
  };
};

export const setIsVoiceCallWithOtherAgent = ({
  isVoiceCallWithOtherAgent,
}) => ({
  type: SET_IS_VOICE_CALL_WITH_OTHER_AGENT,
  isVoiceCallWithOtherAgent,
});

export const setFocusedConversationNextPositionData = ({
  focusedConversationNextPositionData,
}) => ({
  type: SET_FOCUSED_CONVERSATION_NEXT_POSITION_DATA,
  focusedConversationNextPositionData,
});
